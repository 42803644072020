import React from "react";

import Products from "../products";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import "./botellaVino.scss";

const WineBottle = ({ children, subtitle }) => {
  if (!children) {
    if (typeof window !== "undefined") {
      window.location.href = "/products/botellas_vino/burdeos/";
    }
  }
  const Subtitle = subtitle
    ? `Botellas de vino - ${subtitle}`
    : "Botellas de vino";
  return (
    <Products subtitle={Subtitle}>
      <Breadcrumb>
        <Breadcrumb.Item href="/products/botellas_vino/burdeos/">
          Burdeos
        </Breadcrumb.Item>
        {/* <Breadcrumb.Item href="/products/botellas_vino/burdeos-eco/">
          Burdeos Eco
        </Breadcrumb.Item> */}
        <Breadcrumb.Item href="/products/botellas_vino/borgona">
          Borgoña
        </Breadcrumb.Item>
        {/* <Breadcrumb.Item href="/products/botellas_vino/borgona-eco">
          Borgoña Eco
        </Breadcrumb.Item> */}
      </Breadcrumb>
      {children}
    </Products>
  );
};

export default WineBottle;
